<template>
  <div>
    <div class="d-sm-flex mb-3 text-Heading6 Medium d-none">
      {{ $store.getters['app/getRouteTitle'] }}
      <v-spacer></v-spacer>
    </div>

    <v-card flat>
      <v-card-text>
        <v-container>
          <v-form ref="form">
            <v-text-field
              rounded
              label="原登入密碼"
              v-model="form.originalPassword"
              :rules="[$rules.required]"
              outlined
            ></v-text-field>

            <v-text-field
              rounded
              label="新登入密碼"
              v-model="form.newPassword"
              :rules="[$rules.required]"
              outlined
            ></v-text-field>

            <v-text-field
              rounded
              label="確認新密碼"
              v-model="form.confirmPassword"
              :rules="[$rules.required]"
              outlined
            ></v-text-field>

            <v-text-field
              rounded
              label="谷歌驗證碼"
              v-model="form.otp_code"
              :rules="[$rules.required]"
              outlined
            ></v-text-field>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions class="flex-center">
        <v-btn
          rounded
          color="primary"
          depressed
          width="320"
          height="40"
          @click="submit"
          :loading="isLoading"
        >
          保存
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { modifyUserPassword } from '@/api/system';

export default {
  data() {
    return {
      isLoading: false,

      form: {
        originalPassword: '',
        newPassword: '',
        confirmPassword: '',
        otp_code: ''
      }
    };
  },

  methods: {
    close() {
      this.$emit('close');
    },
    async submit() {
      if (!this.$refs.form.validate()) return;

      this.isLoading = true;
      const { success } = await modifyUserPassword({
        ...this.form
      });
      if (success) {
        this.$toast('更新成功');
      } else {
        this.$toast.error('更新失敗');
      }
      this.isLoading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
//
</style>
